'use client'
import { type ReactNode } from 'react'
import { usePathname } from 'next/navigation'
// import dynamic from 'next/dynamic'
import styled from 'styled-components'
// import { useDeviceType } from '@/hooks'
import { mq, vw } from '@/styles'
import { Nav } from '@/components/Nav'

export default function PrivateLayout({ children }: { children: ReactNode }) {
  const pathname = usePathname()
  console.log('PrivateLayout pathname:', pathname)
  return (
    <>
      <Nav />
      <Div>
        {children}
      </Div>
    </>
  )
}

const Div = styled.div<{}>`
  background: var(--color-background);
  height: 100%;
  padding: ${vw(20, 'mobile')} ${vw(30, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(20, 'tablet')} ${vw(30, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(20, 'desktop')} ${vw(30, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding:20px 30px ;
  }


`
