'use client'
import { memo, ReactNode } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { mq, vw, font13_18, font13_19 } from '@/styles'
import { Link } from './Link'
import React from 'react'

interface IButton {
  bgType?:  any,
  children: ReactNode,
  className?: string,
  disabled?: boolean,
  id?: string,
  name?: string
  onClick?: any,
  outline?: boolean,
  type?: 'button' | 'submit',
  uppercase?: boolean,
  noHoverEffect?: boolean,
  initial?: any,
  variants?: any,
  exit?: any,
  animate?: any,
  asLink?: boolean
  custom?: any,
  size?: 'big' | 'medium' | 'small' ,
  href?: string,
  target?: string,
  ariaDisabled?: boolean,
  rel?: string,
  locale?: string,
  title?: string,
  circle?: boolean

}

export const handleBgTypeHover = (bgType) => {
  switch (bgType) {
    case 'light_blue':
      return 'var(--color-white)'
    case 'white':
      return 'var(--color-purple)'
    case 'purple':
      return 'var(--color-white)'
    case 'dark_blue':
      return 'var(--color-dark_blue)'
    default:
      return 'var(--color-white)'
  }
}

export const Button = memo<IButton>(({ bgType = 'white', children, disabled = false, className = '', id, name = 'Button', onClick = null, outline = false, type = 'button', uppercase = false, noHoverEffect = false, initial, variants, exit, animate, custom, size = 'medium', asLink = false, href = '', target = '', rel= '', ariaDisabled = false, title = '', locale  = '', circle= false}) => {
  return (
    <>
      {asLink ?
        <LinkStyled href={href} target={target} {...(id && { id })} className={className} $bgType={bgType}  $uppercase={uppercase} $outline={outline} {...rel && { rel }} {...ariaDisabled && { 'aria-disabled': ariaDisabled }} {...title && { title }} {...locale && { locale }}>
          <div className='link__bg'></div>
          <div>{children}</div>
        </LinkStyled>
        :
        <ButtonStyled {...(id && { id })} className={className} onClick={onClick} type={type} $circle={circle} $bgType={bgType} disabled={disabled} $size={size} $uppercase={uppercase} name={name} $outline={outline} $noHoverEffect={noHoverEffect} {...(initial && { initial })} {...(variants && { variants })} {...(exit && { exit })} {...(animate && { animate })} {...(custom && { custom })} >
          <div className='button__bg'></div>
          <div>{children}</div>
        </ButtonStyled>
      }
    </>
  )
})


const LinkStyled = styled(Link)<any>`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  justify-content: center;
  letter-spacing: 0;
  margin: 0 auto;
  padding: ${vw(15, 'mobile')} ${vw(30, 'mobile')};
  position: relative;
  text-transform: ${({ $uppercase }) => $uppercase ? 'uppercase' : 'initial'};

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(15, 'nexus7')} ${vw(30, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    padding:${vw(15, 'desktop')} ${vw(30, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding: 15px 30px;
  }

  &,
  &:before {
    border-radius:${vw(30, 'mobile')};
    transition: 300ms background-color ${({ ease }) => ease }, 300ms border ${({ ease }) => ease }, 300ms color ${({ ease }) => ease }, 300ms filter ${({ ease }) => ease }, 300ms opacity ${({ ease }) => ease };

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius:${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius:30px;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;

    &:before {
      content: none;
      display: none;
    }
  }

  > div:last-of-type {
    align-items: center;
    color:${({ $bgType }) => handleBgTypeHover($bgType)};
    display: flex;
    height: 100%;
    justify-content: center;
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    width: 100%;
    z-index: 2;
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    white-space: nowrap;

    img {
      padding: ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding: ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 10px;
      }
    }
  }

  .link__bg {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: background-color 0.4s ;
    background-color: ${({ $bgType, $outline }) => $outline ? 'transparent' :`var(--color-${$bgType})`};
    border-radius:${vw(30, 'mobile')};
    border: ${({ $outline, $bgType }) => $outline ? `1px solid ${handleBgTypeHover($bgType)}` : 'none'};

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 30px ;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      background-color: ${({ bgType, $outline }) => $outline ? 'transparent' : handleBgTypeHover(bgType)};
    }

    &:before {
      width: 110%;
      height: 0;
      padding-bottom: 110%;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate3d(-50%,-50%,0) scale3d(0,0,1);
    }

    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  &:hover {
    color: ${({ $bgType }) => handleBgTypeHover($bgType)};

    .link__bg {
      transform: 'none';
      ${mq.greaterThan('tablet')} {
        transform: ${({  $noHoverEffect }) => $noHoverEffect ? 'none' : 'scale3d(1.1,1.2,1)'};
      }

      &:before {
        transition: background-color;
      }

      &:after {
        opacity: 1;
        transition-duration: 0.01s;
        transition-delay: 0.3s;
      }
    }
  }
  ${({ $size }) => $size === 'big' ? sizeBig : $size === 'medium' ? sizeMedium : sizeSmall}

`

const ButtonStyled = styled(motion.button)<any>`
 ${font13_19(true, 400)}
  align-items: center;
  background-color: ${({ $bgType, $outline }) => $outline ? 'transparent' : `var(--color-${$bgType})`};
  color: ${({ $bgType }) =>  handleBgTypeHover($bgType)};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  height: ${({ $circle }) => $circle ? `${vw(38, 'mobile')}` : `${vw(50, 'mobile')}`};
  justify-content: center;
  letter-spacing: 0;
  margin: 0 auto;
  padding: ${({ $circle }) => $circle ? 0 : `${vw(20, 'mobile')} ${vw(15, 'mobile')}`};
  position: relative;
  text-transform: ${({ $uppercase }) => $uppercase ? 'uppercase' : 'initial'};
  width: ${({ $circle }) => $circle ? `${vw(38, 'mobile')}` : 'unset'};

  ${mq.greaterThan('nexus7')} {
    height: ${({ $circle }) => $circle ? `${vw(38, 'tablet')}` : `${vw(50, 'tablet')}`};
    padding: ${({ $circle }) => $circle ? 0 : `${vw(20, 'tablet')} ${vw(15, 'tablet')}`};
    width: ${({ $circle }) => $circle ? `${vw(38, 'tablet')}` : 'unset'};
  }

  ${mq.greaterThan('tablet')} {
    ${font13_19(false, 400)}
    height: ${({ $circle }: any) => $circle ? `${vw(40, 'desktop')}` : `${vw(60, 'desktop')}`};
    padding: ${({ $circle }: any) => $circle ? 0 : `${vw(20, 'desktop')} ${vw(15, 'desktop')}`};
    width: ${({ $circle }: any) => $circle ? `${vw(40, 'desktop')}` : 'unset'};
  }

  ${mq.greaterThan('desktop')} {
    height: ${({ $circle }: any) => $circle ? '40px' : '60px'};
    padding: ${({ $circle }: any) => $circle ? 0 : '20px 15px'};
    width: ${({ $circle }: any) => $circle ? '40px' : 'unset'};
  }

  &[type='submit'] {
    ${font13_19(true, 400)}

    ${mq.greaterThan('tablet')} {
      ${font13_19(false, 400)}
    }
  }

  &,
  &:before {
    border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(30, 'mobile')}`};
    transition: 300ms background-color ${({ ease }) => ease }, 300ms border ${({ ease }) => ease }, 300ms color ${({ ease }) => ease }, 300ms filter ${({ ease }) => ease }, 300ms opacity ${({ ease }) => ease };

    ${mq.greaterThan('nexus7')} {
      border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(30, 'nexus7')}`};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(30, 'desktop')}`};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : '30px'};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;

    &:before {
      content: none;
      display: none;
    }
  }

  > div:last-of-type {
    ${font13_19(true, 400)}
    align-items: center;
    color: inherit;
    display: flex;
    height: 100%;
    justify-content: center;
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    width: 100%;
    z-index: 2;
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    white-space: nowrap;

    ${mq.greaterThan('tablet')} {
      ${font13_19(false, 400)}
    }

    img {
      padding: ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding: ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 10px;
      }
    }
  }

  .button__bg {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    background: ${({ $bgType, $outline }) => $outline ? 'transparent' : `var(--color-${$bgType})`};
    border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(30, 'mobile')}`};
    border: ${({ $bgType, $outline }) => $outline ? `1px solid var(--color-${$bgType})}` : 'none'};

    ${mq.greaterThan('nexus7')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(30, 'nexus7')}`};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(30, 'desktop')}`};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : '30px'};
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      background-color: ${({ bgType, $circle, $outline }) => $circle || $outline ? 'transparent' : handleBgTypeHover(bgType)};
    }

    &:before {
      width: 110%;
      height: 0;
      padding-bottom: 110%;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate3d(-50%,-50%,0) scale3d(0,0,1);
    }

    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  &:hover {
    color: ${({ $bgType }) => $bgType ? `var(--color-${$bgType})` : 'var(--color-white)'};

    .button__bg {
      transform: 'none';

      ${mq.greaterThan('tablet')} {
        transform: ${({ $circle, $noHoverEffect }) => $noHoverEffect ? 'none' : $circle ? 'scale3d(1.2,1.2,1)' : 'scale3d(1.1,1.2,1)'};
      }

      &:before {
        transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
        transform: translate3d(-50%,-50%,0) scale3d(1,1,1);
      }

      &:after {
        opacity: 1;
        transition-duration: 0.01s;
        transition-delay: 0.3s;
      }
    }
  }

  ${({ $size }) => $size === 'big' ? sizeBig : $size === 'medium' ? sizeMedium : sizeSmall}

`

const sizeBig = css`
  height: ${vw(50, 'mobile')};
  padding: ${vw(20, 'mobile')} ${vw(15, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(50, 'tablet')};
    padding: ${vw(20, 'tablet')} ${vw(15, 'tablet')};

  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(50, 'desktop')};
    padding: ${vw(20, 'desktop')} ${vw(15, 'desktop')};

  }

  ${mq.greaterThan('desktop')} {
    height: 50px;
    padding: 20px 15px;
  }

  > div:last-of-type {
    ${font13_19(true, 400)}

    ${mq.greaterThan('tablet')} {
      ${font13_19(false, 400)}
    }
  }
`

const sizeMedium = css`
  ${font13_19(true, 400)}
  height: ${vw(40, 'mobile')};
  padding: ${vw(10, 'mobile')} ${vw(15, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(40, 'tablet')};
    padding: ${vw(10, 'tablet')} ${vw(15, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    ${font13_19(false, 400)}
    height: ${vw(40, 'desktop')};
    padding: ${vw(10, 'desktop')} ${vw(15, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 40px;
    padding: 10px 15px;
  }

  > div:last-of-type {
    font-size: inherit;
  }
`

const sizeSmall = css`
  height: ${vw(25, 'mobile')};
  padding: ${vw(5, 'mobile')} ${vw(10, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(25, 'tablet')};
    padding: ${vw(5, 'tablet')} ${vw(10, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(25, 'desktop')};
    padding: ${vw(5, 'desktop')} ${vw(10, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 25px;
    padding: 5px 10px;
  }

  > div:last-of-type {
    ${font13_18(true, 400)}
    letter-spacing: .5px;

    ${mq.greaterThan('tablet')} {
      ${font13_18(false, 400)}
    }
  }
`
